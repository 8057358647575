// A major bug exists in Safari 15.4 where attempts to smooth-scroll an element
// causes a silent failure and this persists until Safari 16. Since this is an
// actual browser bug that can't be sniffed for, all we can do is disable smooth
// scrolling for Safari 15.x 2022/09/29
const isSafari15 = !!navigator.userAgent.match(/15[.eE]\d+ Safari/i);
const SMOOTH_SCROLL = isSafari15 ? 'instant' : 'smooth';

// Analytics helpers
function setHrefAsDestinationUrl(element, payload) {
    payload['destination_url'] = element.getAttribute('href');
}
function setIndexAsPosition(element, payload) {
    payload['creative_position'] = element.getAttribute('data-index');
}

// Main page analytics
if (document.getElementById("warframe1999")) {
    // Watch trailer link
    /*
    Warframe.addDataLayerTrigger({
        selector: '#masthead-watch-link',
        event: 'click',
        payload: {
            event: 'button_click',
            creative_placement: 'masthead',
            creative_title: 'watch-trailer',
            creative_type: 'button',
        },
        transform: setHrefAsDestinationUrl
    });
    */
    // Play now link
    /*
    Warframe.addDataLayerTrigger({
        selector: '#masthead-play-link',
        event: 'click',
        payload: {
            event: 'button_click',
            creative_placement: 'masthead',
            creative_title: 'sign-up',
            creative_type: 'button',
        },
    });
    */
    const PlayLink = document.getElementById("masthead-play-link");
    if (PlayLink) {
        PlayLink.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            document.getElementById("btnSignup").click();
        })
    }

    // Screenshots
    Warframe.addDataLayerTrigger({
        selector: '.MediaItem .LightboxLink, .MediaItem-link',
        event: 'click',
        payload: {
            event: 'panel_click',
            creative_placement: 'media',
            creative_title: '1999-media',
            creative_type: 'panel',
        },
        transform: setIndexAsPosition
    });
    // Screenshots show more
    Warframe.addDataLayerTrigger({
        selector: '.MediaGrid-moreButton',
        event: 'click',
        payload: {
            event: 'button_click',
            creative_placement: 'media',
            creative_title: 'show-more',
            creative_type: 'button',
        },
    });
    // Lightbox prev
    Warframe.addDataLayerTrigger({
        selector: '#hd-lightbox .Lightbox-prev',
        event: 'click',
        payload: {
            event: 'button_click',
            creative_placement: 'media',
            creative_title: 'prev',
            creative_type: 'button',
        },
    });
    // Lightbox next
    Warframe.addDataLayerTrigger({
        selector: '#hd-lightbox .Lightbox-next',
        event: 'click',
        payload: {
            event: 'button_click',
            creative_placement: 'media',
            creative_title: 'next',
            creative_type: 'button',
        },
    });

    // Featured sections
    // On-Lyne
    Warframe.addDataLayerTrigger({
        selector: '#featured-onlyne',
        event: 'click',
        payload: {
            event: 'panel_click',
            creative_placement: 'masthead',
            creative_title: 'onlyne',
            creative_type: 'panel',
        },
        transform: setHrefAsDestinationUrl
    });
    // Atomicycle
    Warframe.addDataLayerTrigger({
        selector: '#featured-atomicycle',
        event: 'click',
        payload: {
            event: 'panel_click',
            creative_placement: 'masthead',
            creative_title: 'atomicycle',
            creative_type: 'panel',
        },
        transform: setHrefAsDestinationUrl
    });
    // Protoframes
    Warframe.addDataLayerTrigger({
        selector: '#featured-protoframe',
        event: 'click',
        payload: {
            event: 'panel_click',
            creative_placement: 'masthead',
            creative_title: 'protoframe',
            creative_type: 'panel',
        },
        transform: setHrefAsDestinationUrl
    });

}

// Web ring analytics
{
    // Prev
    Warframe.addDataLayerTrigger({
        selector: '.WebRingGrid-prev',
        event: 'click',
        payload: {
            event: 'link_click',
            creative_placement: 'webring',
            creative_title: 'prev',
            creative_type: 'link',
        },
        transform: setHrefAsDestinationUrl
    });
    // Random
    Warframe.addDataLayerTrigger({
        selector: '.WebRingGrid-random',
        event: 'click',
        payload: {
            event: 'link_click',
            creative_placement: 'webring',
            creative_title: 'random',
            creative_type: 'link',
        },
        transform: setHrefAsDestinationUrl
    });
    // Next
    Warframe.addDataLayerTrigger({
        selector: '.WebRingGrid-next',
        event: 'click',
        payload: {
            event: 'link_click',
            creative_placement: 'webring',
            creative_title: 'next',
            creative_type: 'link',
        },
        transform: setHrefAsDestinationUrl
    });
}

const MediaGridElement = document.querySelector("#warframe1999 .MediaGrid");
if (MediaGridElement) {
    const MediaGridMoreLink = MediaGridElement.querySelector('.MediaGrid-moreButton');
    MediaGridMoreLink && MediaGridMoreLink.addEventListener('click', (e) => {
        MediaGridElement.classList.toggle('is-collapsed');
    });

    const MediaGridWrapper = MediaGridElement.querySelector('.MediaGrid-wrapper');
    const MediaGridInner = MediaGridElement.querySelector('.MediaGrid-inner');

    let lastUpdateTimestamp = 0;
    function syncHeight() {
        requestAnimationFrame((timestamp) => {
            if (lastUpdateTimestamp < timestamp) {
                const height = MediaGridInner.offsetHeight;
                MediaGridWrapper.style.setProperty('--grid-height', height + 'px');
                lastUpdateTimestamp = timestamp;
            }
        });
    }
    syncHeight();
    document.addEventListener('scroll', syncHeight);
    document.addEventListener('resize', syncHeight);
}

const OnLyneGrid = document.querySelector("#on-lyne .MainGrid");
if (OnLyneGrid != null) {
    const OnLyneCharacterCards = OnLyneGrid.querySelectorAll(".CharacterCard");
    const OnLyneCharacterPages = OnLyneGrid.querySelectorAll(".MainGridPage");
    let glitchTimeout;

    function resetCards() {
        window.forEachNode(OnLyneCharacterCards, (other) => {
            other.classList.toggle('is-active', false)
            other.classList.toggle('is-inactive', false)
            other.classList.toggle('is-glitchy', false)
            other.classList.toggle('is-glitched', false)
            other.classList.toggle('is-glitched-out', false)
        });
        OnLyneGrid.classList.toggle('is-active', false);
        window.forEachNode(OnLyneCharacterPages, (page) => {
            page.classList.toggle('is-active', page.getAttribute("data-index") === "0");
            page.classList.toggle('is-glitched', false)
            page.classList.toggle('is-glitched-out', false)
        });
    }

    const OnLyneSection = document.querySelector("#on-lyne .MainSection");
    OnLyneSection && OnLyneSection.addEventListener('click', (e) => {
       resetCards();
    });

    window.forEachNode(OnLyneCharacterCards, (card) => {
        const index = card.getAttribute("data-index");
        const linkedPage = OnLyneGrid.querySelector(`.MainGridPage[data-index="${index}"]`);
        card.addEventListener('click', (e) => {
            e.stopPropagation();
            clearTimeout(glitchTimeout);
            if (card.classList.contains('is-active')) {
                if (card.classList.contains('is-glitchy')) {
                    e.stopPropagation();
                    card.classList.toggle('is-glitchy', false)
                    card.classList.toggle('is-glitched', true)
                    linkedPage.classList.toggle('is-glitched', true)
                }
                else
                {
                    resetCards();
                }
            } else {
                window.forEachNode(OnLyneCharacterCards, (other) => {
                    other.classList.toggle('is-active', other === card)
                    other.classList.toggle('is-inactive', other !== card)
                    other.classList.toggle('is-glitchy', other === card)
                    other.classList.toggle('is-glitched', false)
                    other.classList.toggle('is-glitched-out', false)
                });
                OnLyneGrid.classList.toggle('is-active', true);
                window.forEachNode(OnLyneCharacterPages, (page) => {
                    page.classList.toggle('is-active', page.getAttribute("data-index") === index);
                    page.classList.toggle('is-glitched', false)
                    page.classList.toggle('is-glitched-out', false)
                });
            }
        });
    });
}

const OnLyneMediaPlayer = document.querySelector("#on-lyne .MediaPlayer");
if (OnLyneMediaPlayer != null) {
    const MediaPlayerAudio = OnLyneMediaPlayer.querySelector(".MediaPlayer-audio");
    const MediaPlayerTrack = OnLyneMediaPlayer.querySelector(".MediaPlayer-track");
    const MediaPlayerDuration = OnLyneMediaPlayer.querySelector(".MediaPlayer-duration");
    const MediaPlayerPlay = OnLyneMediaPlayer.querySelector(".MediaPlayer-play");
    const MediaPlayerPause = OnLyneMediaPlayer.querySelector(".MediaPlayer-pause");
    const MediaPlayerStop = OnLyneMediaPlayer.querySelector(".MediaPlayer-stop");

    function syncPlayerControls() {
        MediaPlayerPlay.classList.toggle('is-pressed', !MediaPlayerAudio.paused);
        MediaPlayerPause.classList.toggle('is-pressed', MediaPlayerAudio.paused && MediaPlayerAudio.currentTime > 0);
    }

    MediaPlayerPlay.addEventListener('click', (e) => {
        e.stopPropagation();
        MediaPlayerAudio.play();
        syncPlayerControls();
    });
    MediaPlayerPause.addEventListener('click', (e) => {
        e.stopPropagation();
        MediaPlayerAudio.pause();
        syncPlayerControls();
    });
    MediaPlayerStop.addEventListener('click', (e) => {
        e.stopPropagation();
        if (!MediaPlayerAudio.paused) MediaPlayerAudio.pause();
        MediaPlayerAudio.currentTime = 0;
        syncPlayerControls();
    });

    let track = MediaPlayerTrack.innerText + " ---- ";
    let duration = "0:00";
    let flicker = false;
    MediaPlayerTrack.innerHTML = track;
    setInterval(() => {
        track = track.substring(1) + track.substring(0, 1);
        MediaPlayerTrack.innerHTML = track;
        if (!MediaPlayerAudio.paused || MediaPlayerAudio.currentTime !== 0) {
            const minutes = Math.floor(MediaPlayerAudio.currentTime / 60);
            const seconds = Math.floor(MediaPlayerAudio.currentTime % 60);
            duration = minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + ':' + seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
            flicker = false;
        }
        else
        {
            const minutes = Math.floor(MediaPlayerAudio.duration / 60);
            const seconds = Math.floor(MediaPlayerAudio.duration % 60);
            duration = minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + ':' + seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
            flicker = Math.floor(Date.now() / 1000) % 2 === 0;
        }
        MediaPlayerDuration.innerHTML = duration;
        MediaPlayerDuration.classList.toggle('is-flicker', flicker);
        syncPlayerControls();
    }, 500);
}

const MastheadSlideshow = document.querySelector(".MastheadSlideshow");
if (MastheadSlideshow) {
    const images = MastheadSlideshow.querySelectorAll(".MastheadSlideshow-slide");
    const controls = MastheadSlideshow.querySelectorAll(".MastheadSlideshow-control");
    if (images.length !== controls.length) {
        console.error("Error: Slideshow slide and control count mismatch");
    }
    else
    {
        let advanceTimeout;
        let activeSlideIndex = 0;
        const slides = [];

        function swapSlide(index) {
            if (index !== activeSlideIndex) {
                clearTimeout(advanceTimeout);
                const previousSlideIndex = activeSlideIndex;
                activeSlideIndex = index;
                for (let i = 0; i < slides.length; i ++) {
                    const {image, control} = slides[i];
                    image.classList.toggle('is-matte', i === previousSlideIndex);
                    image.classList.toggle('is-active', i === activeSlideIndex);
                    control.classList.toggle('is-active', i === activeSlideIndex);
                }
                advanceTimeout = setTimeout(advanceSlide, 5000);
            }
        }
        function advanceSlide() {
            let index = activeSlideIndex + 1;
            if (index >= slides.length) index = 0;
            swapSlide(index);
        }

        for (let i = 0; i < images.length; i ++) {
            const index = i;
            slides.push({
               image: images[i],
               control: controls[i]
            });
            controls[i].addEventListener('click', (e) => {
               e.stopPropagation();
               swapSlide(index);
            });
        }

        document.addEventListener("DOMContentLoaded", function() {
            advanceTimeout = setTimeout(advanceSlide, 5000);
        });
    }
}

const AtomicycleNavButtons = document.querySelectorAll("#atomicycle .PageNav-button");
if (AtomicycleNavButtons != null) {
    const AtomicycleBodyPages = document.querySelectorAll("#atomicycle .BodyPage");

    function syncNavButtons(activeButton) {
        window.forEachNode(AtomicycleNavButtons, (button) => {
            button.classList.toggle('is-active', button === activeButton);
        });
    }
    function syncBodyPages(index) {
        window.forEachNode(AtomicycleBodyPages, (page) => {
            page.classList.toggle('is-active', page.getAttribute("data-index") === index);
        });
    }

    window.forEachNode(AtomicycleNavButtons, (button) => {
        if (button.hasAttribute("data-index")) {
            button.addEventListener("click", (e) => {
                syncNavButtons(button);
                syncBodyPages(button.getAttribute("data-index"));
            });
        }
    });
}

const DraggableElements = document.querySelectorAll(".DraggableElement");
if (DraggableElements) {
    const DraggableReset = document.querySelector('.DraggableReset');
    const draggables = [];

    window.forEachNode(DraggableElements, (draggable) => {
        let startX, startY, startLeft, startTop;
        let deltaX, deltaY;
        let angle = 0;

        function getClientPos(e) {
            if (e.touches && e.touches.length > 0) {
                return {
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY
                };
            }

            return {
                x: e.clientX,
                y: e.clientY
            };
        }

        function dragStart(e) {
            e.preventDefault();
            const {x, y} = getClientPos(e);
            startX = x;
            startY = y;
            startLeft = draggable.offsetLeft;
            startTop = draggable.offsetTop;

            document.addEventListener('mousemove', drag);
            document.addEventListener('touchmove', drag, {passive:false});
            document.addEventListener('mouseup', dragStop);
            document.addEventListener('touchend', dragStop, {passive:false});
        }

        function drag(e) {
            e.preventDefault();
            const {x, y} = getClientPos(e);
            deltaX = x - startX;
            deltaY = y - startY;

            draggable.style.left = (startLeft + deltaX) + 'px';
            draggable.style.top = (startTop + deltaY) + 'px';

            if (e.touches && e.touches.length > 1) {
                const dx = e.touches[1].clientX - e.touches[0].clientX;
                const dy = e.touches[1].clientY - e.touches[0].clientY;
                angle = Math.atan2(dy, dx) * (180 / Math.PI);
                draggable.style.transform = `rotate(${angle}deg)`;
            }
            DraggableReset && DraggableReset.classList.toggle("is-hidden", false);
        }

        function dragStop(e) {
            e.preventDefault();
            document.removeEventListener('mousemove', drag);
            document.removeEventListener('touchmove', drag);
            document.removeEventListener('mouseup', dragStop);
            document.removeEventListener('touchend', dragStop);
        }

        function rotate(e) {
            e.preventDefault();
            if (e.deltaY) {
                angle += e.deltaY / 20;
                draggable.style.transform = `rotate(${angle}deg)`;
            }
            DraggableReset && DraggableReset.classList.toggle("is-hidden", false);
        }

        function reset() {
            draggable.style.top = null;
            draggable.style.left = null;

            angle = 0;
            draggable.style.transform = null;
        }

        draggable.addEventListener('mousedown', dragStart);
        draggable.addEventListener('touchstart', dragStart, {passive:false});
        draggable.addEventListener('wheel', rotate, {passive:false});

        draggables.push({
           element: draggable,
           reset
        });
    });
    if (DraggableReset) {
        DraggableReset.addEventListener('click', (e) => {
           e.stopPropagation();
           e.preventDefault();
           for (const draggable of draggables) {
               draggable.reset();
           }
            DraggableReset.classList.toggle("is-hidden", true);
        });
    }
}

const DossierTiles = document.querySelectorAll(".DossierTile");
if (DossierTiles && DossierTiles.length > 0) {
    const TileData = [];
    let currentTileIndex = -1;

    window.forEachNode(DossierTiles, (tile, tileIndex) => {
        const container = tile.querySelector(".DossierTile-container");
        function toggleTile() {
            tile.classList.toggle('is-flipped');
            tile.classList.toggle('is-unflipped', !tile.classList.contains('is-flipped'));
        }
        container.addEventListener('click', (e) => {
            currentTileIndex = tileIndex;
            if (tile.classList.contains('is-blurred')) {
                scrollToTile(tile, true);
                setTimeout(toggleTile, 250);
            }
            else
            {
                toggleTile();
            }
        });
        TileData.push({
           element: tile,
           container
        });
    });

    const DossierWrapper = document.querySelector(".DossierGrid-tileWrapper");

    function getCenterline() {
        const rect = DossierWrapper.getBoundingClientRect();
        if (PageComponents.isDesktopUp()) {
            return rect.left + rect.width * 0.625;
        }
        return rect.left + rect.width / 2;
    }
    function syncTiles() {
        const centerline = getCenterline();
        let dist = 9999;
        let closest = null;
        window.forEachNode(TileData, ({element, container}) => {
            const rect = container.getBoundingClientRect();
            const midpoint = rect.left + rect.width / 2;
            const dx = Math.abs(centerline - midpoint);

            if (dx < dist) {
                closest = element;
                dist = dx;
            }
        });
        window.forEachNode(TileData, ({element}) => {
            element.classList.toggle('is-blurred', element !== closest);
            if (element !== closest) element.classList.toggle('is-flipped', false);
        });
    }

    function scrollToTile(element, smooth=false) {
        const centerline = getCenterline();
        const rect = element.getBoundingClientRect();
        const midpoint = rect.left + rect.width / 2;
        const delta = midpoint - centerline;
        DossierWrapper.scrollBy({left: delta, behavior: smooth ? 'smooth' : 'instant'});
    }
    function prevTile() {
        if (currentTileIndex > 0) {
            currentTileIndex--;
            scrollToTile(TileData[currentTileIndex].element, true);
        }
    }
    function nextTile() {
        if (currentTileIndex < (TileData.length - 1)) {
            currentTileIndex++;
            scrollToTile(TileData[currentTileIndex].element, true);
        }
    }
    document.querySelector(".DossierGrid-prev").addEventListener('click', prevTile);
    document.querySelector(".DossierGrid-next").addEventListener('click', nextTile);

    document.addEventListener('DOMContentLoaded', () => {
        const arthur = document.querySelector('.DossierTile[data-index="0"]');
        currentTileIndex = 2;
        scrollToTile(arthur);

        syncTiles();
        DossierWrapper.addEventListener('scroll', syncTiles);
        window.addEventListener('resize', syncTiles);
    });
}

const MetroStatic = document.querySelector(".SubwayGrid-static");
if (MetroStatic) {
    setInterval(() => {
        MetroStatic.style.backgroundPositionX = (Math.random() * 100) + "%";
    }, 1000/30);
}
